import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

const NotFoundTemplate = ({ data }) => {
  const { title, main_title } = data.contentfulPage;

  return (
    <Layout>
      <Seo title={title}>
        <meta property="robots" content="noindex" />
      </Seo>
      <section className="section section-gradient">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <p className="paragraph font-weight-bold text-yellow text-center">
                {title}
              </p>
              <h1 className="text-center">{main_title}</h1>
              <hr />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundTemplate;

export const query = graphql`
  query ($id: String!, $slug: String!, $locale: String!) {
    allSitePage(filter: { context: { id: { eq: $id } } }) {
      nodes {
        path
        context {
          locale
        }
      }
    }
    allContentfulPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        contentful_id
        title
        slug
        category
      }
    }
    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      title
      main_title
    }
    contentfulHotel(
      contentful_id: { eq: "7Ghq3MGGxup1gZhcPpUnH" }
      node_locale: { eq: $locale }
    ) {
      meta_description
      hero_image {
        file {
          url
        }
      }
    }
  }
`;
